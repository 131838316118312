import styled from "styled-components";
import { ContainerBg } from "./home/Home.styled";
import Socials from "./Socials";
import { Link } from "react-router-dom";

const Footer = () => {
      return (
            <StyledFooter>
                  <div className="container-fluid">
                        <ContainerBg
                              data-aos="zoom-in"
                              data-aos-duration="1000"
                        >
                              <div className="text-center ">
                                    <div className="d-flex justify-content-center">
                                          <Socials />
                                    </div>
                                    <div className="">
                                          <small>
                                                © 2025 {' '}
                                                <Link to='https://hammedakin.xyz'>
                                                      <span className="sec-text fw-bold">
                                                            HAMMEDAKIN
                                                      </span>{' '}
                                                </Link>
                                                All Rights Reserved
                                          </small>
                                    </div>
                              </div>
                        </ContainerBg>
                  </div>
            </StyledFooter>
      );
};

export default Footer;


const StyledFooter = styled.footer`
      margin: 4rem 0 0rem;
      padding-bottom: 2rem;

`;